<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">中奖纪录</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex flex-wrap align-items-center">
				<el-date-picker class="m-1" size="small" @change="changeTime" v-model="time" type="datetimerange"
					value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
				<div class="d-flex align-items-center m-2">
					<div class="label ">状态：</div>
					<el-select size="small" style="width: 120px" v-model="search.status" @change="toSearch"
						placeholder="奖品类型">
						<el-option label="全部" :value="0"></el-option>
						<el-option label="待发货" value="10"></el-option>
						<el-option label="已发货" value="20"></el-option>
					</el-select>
				</div>
				<div>
					<el-input style="width: 300px" size="small" v-model="search.keyword" placeholder="请输入搜索内容" clearable
						@clear="toSearch" @keyup.enter.native="toSearch">
						<el-select style="width: 120px" slot="prepend" v-model="search.keyword_type">
							<el-option v-for="item in selectList" :key="item.value" :label="item.name"
								:value="item.value">
							</el-option>
						</el-select>
					</el-input>
				</div>
				<el-button @click="handleExport" class="ml-2" type="primary" size="mini">导出</el-button>
				<router-link :to="{name:'prize.record/batchdelivery'}" class="mr-2">
					<el-button class="ml-2" type="primary" size="mini">批量发货</el-button>
				</router-link>
				<div class="ml-auto">
					<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
						style="display: inline-block;float: right;" background @current-change="pageChange"
						layout="prev, pager, next" :total="lists.total">
					</el-pagination>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%" :default-expand-all="true">
				<el-table-column type="expand">
					<template slot-scope="scope">
						<div class="d-flex">
							<div style="font-weight: 700;width: 40%;">
								订单编号：{{scope.row.order_no}}
							</div>
							<div style="font-weight: 700;">
								地址信息：<span>{{scope.row.real_name}}&nbsp;&nbsp;&nbsp;</span><span>{{scope.row.mobile}}&nbsp;&nbsp;&nbsp;&nbsp;</span>
								<span>{{scope.row.address}} </span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" width="110" prop="prize_record_id" label="中奖记录ID"></el-table-column>
				<el-table-column align="center" label="用户头像" width="110">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.user.avatarUrl">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="昵称">
					<template slot-scope="scope">
						<span style="color: red;font-weight: 800;">{{scope.row.user.nickName}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="name" label="奖品名称"></el-table-column>
				<el-table-column align="center" prop="lv" label="中奖概率">
					<template slot-scope="scope">
						<span style="color: #67C23A;font-weight: bold;">{{scope.row.lv}} %</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="奖品图片" width="120">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.image">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" width="125" label="状态">
					<template slot-scope="scope">
						<el-tag size="mini" v-if="scope.row.status == 10">未发货</el-tag>
						<el-tag size="mini" type="danger" v-if="scope.row.status == 20">已发货</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" label="中奖时间" width="180">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="text" size="mini" v-if="scope.row.status == 10" @click="openDialog(scope.row)"
							class="table-btn">去发货</el-button>
							<el-button type="text" size="mini" v-else @click="openDialog(scope.row)"
								class="table-btn">编辑物流</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog title="去发货" :visible.sync="dialogVisible" top="3vh" width="650px" height="60%">
				<!-- 添加/修改规则 -->
				<el-form :rules="rules" ref="form" :model="form" label-width="100px">
					<el-form-item label="物流公司" prop="express_id">
						<el-select v-model="form.express_id" placeholder="物流公司">
							<el-option :label="item.express_name" v-for="item in expressList" :value="item.express_id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="物流单号" prop="express_no">
						<el-input v-model="form.express_no" placeholder="物流单号"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submit('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				dialogVisible: false,
				expressList: [],
				form: {
					express_id: '',
					express_no: '',
				},
				prize_record_id: 0,
				rules: {
					express_id: [{
						required: true,
						message: '请输入物流公司',
						trigger: 'change'
					}],
					express_no: [{
						required: true,
						message: '请输入物流单号',
						trigger: 'change'
					}],
				},
				time: [],
				lists: [],
				search: {
					s: 'store/prize.record/index',
					page: 1,
					type: 0,
					status: 0,
					keyword: '',
					keyword_type: '1',
					start_time: null,
					end_time: null,
				},
				selectList: [{
						value: '1',
						name: '昵称'
					},
					{
						value: '2',
						name: '姓名'
					},
					{
						value: '3',
						name: '手机号'
					},
					{
						value: '4',
						name: '地址'
					},
					{
						value: '5',
						name: '奖品信息'
					},
					{
						value: '6',
						name: '订单编号'
					},
				],
			}
		},
		created() {
			this.getList();
			this.getExpressList();
		},
		methods: {
			openDialog(item) {
				// 显示弹出层
				this.dialogVisible = true
				this.form = {
					express_id: item.express_id == 0 ? '' : item.express_id,
					express_no: item.express_no,
				},
				this.prize_record_id = item.prize_record_id
			},
			// 提交发货
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/prize.record/delivery',
								prize_record_id: this.prize_record_id
							},
							data: {
								record: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogVisible = false;
								this.getList()
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
			getExpressList() {
				this.axios({
					token: true,
					params: {
						s: 'store/data.express/index',
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						this.expressList = res.data.data
					}
				}).catch(err => {})
			},
			handleExport() {
				this.axios({
					token: true,
					params: {
						s: 'store/prize.record/export',
						type: this.search.type,
						status: this.search.status,
						keyword: this.search.keyword,
						keyword_type: this.search.keyword_type,
						start_time: this.search.start_time,
						end_time: this.search.end_time,
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						window.location.href = this.$conf.base_url + 'prize_record.xls'
					}
				}).catch(err => {})
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			changeTime() {
				if (this.time) {
					this.search.start_time = this.time[0];
					this.search.end_time = this.time[1];
				} else {
					this.search.start_time = null;
					this.search.end_time = null;
				}
				this.toSearch();
			},
			toSearch() {
				this.search.page = 1;
				this.getList();
			},
			// 删除单个
			deleteItem(column) {
				this.$confirm('是否要删除该奖品吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/prize.record/delete',
							prize_record_id: column.prize_record_id,
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>
